<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="Are you sure?" size="lg">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2 mr-1"
        variant="outline-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="danger"
        @click="deleteLegal"
      >
        Delete
      </b-button>
    </b-modal>
    <b-sidebar
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      no-close-on-backdrop
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div
              style="width: 70%"
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h4 class="font-weight-bolder">
                <span class="align-middle">New Legal Requirement</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                @click="closeSidebar()"
                variant="outline-secondary"
                ><feather-icon size="24" icon="XIcon"
              /></b-button>
            </div>
          </div>
          <div class="p-1">
            <b-form-group label="Title" label-for="defaultLabel">
              <template slot="label">
                Title <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="defaultLabel"
                placeholder="Title"
                v-model="title"
              />
            </b-form-group>
            <b-form-group label="Requirement" label-for="defaultLabel">
              <template slot="label">
                Requirement <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                id="textarea-default"
                placeholder="Requirement"
                rows="3"
                v-model="content"
              />
            </b-form-group>

            <!-- <label for="textarea-default">Effective Date</label>
            <flat-pickr v-model="dateDefault" class="form-control" /> -->
            <div class="row mt-1">
              <div class="col-6">
                <b-form-group label="Internal P.O.C" label-for="defaultLabel">
                  <template slot="label">
                    Internal P.O.C <span class="text-danger">*</span>
                  </template>
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="internalpoc"
                  >
                    <option
                      :value="user.user_id"
                      v-for="user in this.userslist.data"
                      :key="user"
                    >
                      {{ user.firstname }}
                    </option>
                  </select>
                </b-form-group>
                <!-- {{userslist}} -->
              </div>
              <div class="col-6">
                <b-form-group label="Type" label-for="defaultLabel">
                  <template slot="label">
                    Primary Type <span class="text-danger">*</span>
                  </template>
                  <select name="" id="" class="form-control" v-model="type">
                    <option value="" disabled selected>Select a Type</option>
                    <option value="legal">Legal</option>
                    <option value="regulatory">Regulatory</option>
                    <option value="contractual">Contractual</option>
                  </select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group label="Authority" label-for="defaultLabel">
                  <template slot="label">
                    Authority <span class="text-danger"></span>
                  </template>
                  <b-form-input
                    id="defaultLabel"
                    placeholder="Enter Authority"
                    v-model="authority"
                  />
                </b-form-group>
              </div>
            </div>
            <!-- <label for="textarea-default">Next assessment Date</label>
            <flat-pickr v-model="dateDefault" class="form-control mb-1" /> -->
            <!-- <b-form-group label="Country" label-for="defaultLabel">
              <b-form-input
                id="defaultLabel"
                placeholder="Country"
                v-model="country"
              />
            </b-form-group> -->
            <button class="btn btn-primary mr-1" @click="createLegalRegister">
              Add
            </button>
            <button class="btn btn-secondary" @click="closeSidebar">
              Cancel
            </button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar
      id="sidebar-edit"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      no-close-on-backdrop
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div
              style="width: 70%"
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h4 class="font-weight-bolder">
                <span class="align-middle">Update Legal Requirement</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                @click="closeEditLegal()"
                variant="outline-secondary"
                ><feather-icon size="24" icon="XIcon"
              /></b-button>
            </div>
          </div>

          <div class="p-1">
            <b-form-group label="Title" label-for="defaultLabel">
              <b-form-input
                id="defaultLabel"
                placeholder="Title"
                v-model="title"
              />
            </b-form-group>
            <label for="textarea-default">Requirement</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Requirement"
              rows="3"
              v-model="content"
            />

            <!-- <label for="textarea-default">Effective Date</label>
            <flat-pickr v-model="dateDefault" class="form-control" /> -->
            <div class="row mt-1">
              <div class="col-6">
                <label for="">Internal P.O.C</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="internalpoc"
                >
                  <option
                    :value="user.user_id"
                    v-for="user in this.userslist.data"
                    :key="user"
                  >
                    {{ user.firstname }}
                  </option>
                </select>
                <!-- {{userslist}} -->
              </div>
              <div class="col-6">
                <label for="">Primary Type</label>
                <select name="" id="" class="form-control" v-model="type">
                  <option value="" disabled selected>Select a Type</option>
                  <option value="legal">Legal</option>
                  <option value="regulatory">Regulatory</option>
                  <option value="contractual">Contractual</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group label="Authority" label-for="defaultLabel">
                  <b-form-input
                    id="defaultLabel"
                    placeholder="Enter Authority"
                    v-model="authority"
                  />
                </b-form-group>
              </div>
            </div>
            <!-- <label for="textarea-default">Next assessment Date</label>
            <flat-pickr v-model="dateDefault" class="form-control mb-1" /> -->
            <!-- <b-form-group label="Country" label-for="defaultLabel">
              <b-form-input
                id="defaultLabel"
                placeholder="Country"
                v-model="country"
              />
            </b-form-group> -->
            <button class="btn btn-primary mr-1" @click="updateLegalRegister">
              Update
            </button>
            <button class="btn btn-secondary" @click="closeEditLegal">
              Cancel
            </button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar
      id="sidebar-applicable"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      no-close-on-backdrop
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div
              style="width: 70%"
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h4 class="font-weight-bolder">
                <span class="align-middle">Applicability Review</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button
                size="sm"
                @click="closeApplicable()"
                variant="outline-secondary"
                ><feather-icon size="24" icon="XIcon"
              /></b-button>
            </div>
          </div>

          <div class="p-1">
            <b-form-group label="Applicability" label-for="defaultLabel">
              <template slot="label">
                Applicability <span class="text-danger">*</span>
              </template>
              <select
                name=""
                id=""
                class="form-control"
                v-model="applicability"
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </b-form-group>
            <b-form-group label="Applicability Note" label-for="defaultLabel">
              <template slot="label">
                Applicability Note <span class="text-danger">*</span>
              </template>
              <b-form-textarea
                id="textarea-default"
                placeholder="Enter Note"
                rows="3"
                v-model="applicability_note"
              />
            </b-form-group>
            <b-form-group label="Assessment Date" label-for="defaultLabel">
              <template slot="label">
                Assessment Date <span class="text-danger">*</span>
              </template>
              <flat-pickr v-model="assessmentDate" class="form-control" />
            </b-form-group>
            <b-form-group label="Interested Parties">
              <template slot="label">
                Interested Parties <span class="text-danger">*</span>
              </template>
              <div class="d-flex">
                <v-select
                  taggable
                  v-model="partyname"
                  label="text"
                  :options="parties_suggestion"
                  @search="getIntrestedParties"
                  @option:selected="AddParties(partyname)"
                  autocomplete
                  state="errors.length > 0 ? false:null"
                  style="width: 100%"
                />

                <!-- <input type="text" class="form-control" v-model="partyname" @input="getIntrestedParties">
                <button class="btn btn-sm btn-primary ml-1" @click="AddParties(partyname)" :disabled="partyname == ''">+</button> -->
              </div>
              <label for="" class="mt-1"
                ><span v-if="parties.length > 0">Added Parties</span></label
              >
              <ul style="padding: 15px !important">
                <li v-for="(party, index) in parties">
                  <span
                    class="d-flex align-items-center justify-content-between"
                  >
                    <span>{{ party }}</span>
                    <span
                      @click="removeParties(index)"
                      style="cursor: pointer"
                      class="text-danger"
                      >X</span
                    >
                  </span>
                </li>
              </ul>
            </b-form-group>

            <button class="btn btn-primary mr-1" @click="AddReview">
              Add Review
            </button>
            <button class="btn btn-secondary" @click="closeApplicable">
              Cancel
            </button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-modal
      ref="importLegalRegister"
      hide-footer
      title="Import Legal Register "
      size="lg"
      no-close-on-backdrop
      @hide="clearInputsModal()"
    >
      <label for=""
        >Select an Internal P.O.C <span class="text-danger">*</span></label
      >
      <v-select
        v-model="selectedInternalPOC"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="optionUsers"
      />
      <label class="mt-1" for=""
        >Select an Country <span class="text-danger">*</span></label
      >
      <v-select
        v-model="selectedCountry"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="countries"
        @search="fetchCountry"
        placeholder="Search Country"
        :reduce="(selectedCountry) => selectedCountry.value"
      />
      <label class="mt-1" for=""
        >Select an Industry <span class="text-danger">*</span></label
      >
      <v-select
        v-model="selectedIndustry"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="industries"
        @search="fetchIndustry"
        placeholder="Search Industry"
        :reduce="(selectedIndustry) => selectedIndustry.value"
      />
      <button
        class="btn btn-primary btn-sm mt-1"
        @click="importLRfromOnecsf"
        :disabled="
          !selectedInternalPOC || !selectedCountry || !selectedIndustry
        "
      >
        <span v-if="!importStatus"
          ><feather-icon class="mr-75" icon="DownloadIcon" />Import</span
        ><span v-else>Please wait.. </span>
      </button>
    </b-modal>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex mt-3">
              <button @click="openSidebar" class="mb-75 btn btn-sm btn-success">
                <feather-icon class="mr-75" icon="PlusIcon" />Add New
              </button>
              <button
                class="mb-75 btn btn-sm ml-1 mr-1 btn-primary"
                @click="importLegalRegModel"
              >
                <feather-icon class="mr-75" icon="DownloadIcon" />Import Legal
                Requirement
              </button>
            </div>
            <div class="d-flex">
              <div class="mr-1 mt-50">
                <label for="">Search Legal Requirement</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search.."
                  v-model="searchLegalTitle"
                  @input="getLegalList"
                  style="width: 260px"
                />
                <!-- <b-input-group
                  class="input-group-merge"

                >
                  <b-input-group-prepend is-text>
                    <feather-icon
                      v-if="searchLegalTitle == null || searchLegalTitle == ''"
                      icon="SearchIcon"
                      class="text-muted"
                    />
                    <feather-icon
                      @click="clearSearch()"
                      v-else
                      icon="XIcon"
                      class="text-danger cursor-pointer"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    @input="getLegalList()"
                    v-model="searchLegalTitle"
                    placeholder="Search..."
                    debounce="500"
                    size="md"
                    style="width: 260px"
                  />
                </b-input-group> -->
              </div>
              <div class="mr-1 mt-50">
                <label for="">Filter by Country</label>
                <v-select
                  v-model="countryy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="countries"
                  @search="fetchCountry"
                  placeholder="Search Country"
                  :reduce="(countryy) => countryy.value"
                  style="min-width: 250px"
                />
              </div>
              <div class="mt-50">
                <label for="">Filter by Industry</label>
                <v-select
                  v-model="industryy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="industries"
                  @search="fetchIndustry"
                  placeholder="Search Industry"
                  :reduce="(industryy) => industryy.value"
                  style="min-width: 250px"
                />
              </div>
            </div>
          </div>

          <table role="table" class="table table-hover mt-1">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Legal Requirement No</th>
                <th role="columnheader" scope="col">Title</th>
                <th role="columnheader" scope="col">Primary Type</th>
                <th role="columnheader" scope="col">Authority</th>
                <th role="columnheader" scope="col">Internal P.O.C</th>
                <th role="columnheader" scope="col">Last Assessment date</th>
                <th role="columnheader" scope="col">Applicability</th>
                <th role="columnheader" scope="col">Action</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <!-- {{this.legallist}} -->
              <template v-if="legallist && legallist.length > 0">
                <tr
                  v-for="legal in this.legallist"
                  :key="legal"
                  role="row"
                  class="cursor-pointer"
                  @click="viewLegalRegister(legal._id)"
                >
                  <td aria-colindex="2" role="cell">
                    {{ legal.legal_register_number }}
                  </td>
                  <td
                    aria-colindex="2"
                    role="cell"
                    style="width: 200px"
                    title="Click to View Legal Register Details"
                  >
                    {{ legal.title }}
                  </td>

                  <td aria-colindex="2" role="cell" style="width: 200px">
                    <!-- {{legal.type}} -->
                    <b-badge
                      :variant="
                        legal.type == 'legal'
                          ? 'primary'
                          : legal.type == 'regulatory'
                          ? 'warning'
                          : legal.type == 'contractual'
                          ? 'info'
                          : ''
                      "
                      class="text-capitalize"
                    >
                      {{ legal.type }}
                    </b-badge>
                  </td>

                  <td aria-colindex="2" role="cell" style="padding-right: 0">
                    {{ legal.authority }}
                  </td>

                  <td aria-colindex="2" role="cell" style="padding-right: 0">
                    <template
                      v-if="typeof legal.internal_poc_user != 'undefined'"
                    >
                      <span class="">
                        {{
                          legal.internal_poc_user
                            ? legal.internal_poc_user.firstname
                            : ""
                        }}
                      </span>
                      <span>{{
                        legal.internal_poc_user
                          ? legal.internal_poc_user.lastname
                          : ""
                      }}</span>
                    </template>
                    <b-badge v-else variant="danger">Deleted User</b-badge>
                  </td>
                  <td>
                    <!-- {{
          legal.latest_legal_assessment == null && legal.latest_legal_assessment.length == 0
            ? ""
            : legal.latest_legal_assessment[0].assessment_date | moment
        }} -->
                    {{
                      legal.latestLegalAssessment
                        ? legal.latestLegalAssessment.assessment_date
                        : "" | moment
                    }}
                  </td>
                  <td>
                    <b-badge
                      variant="primary"
                      v-if="legal.latestLegalAssessment != null"
                    >
                      {{
                        legal.latestLegalAssessment.applicability == 1
                          ? "Yes"
                          : "No"
                      }}
                    </b-badge>
                  </td>
                  <td aria-colindex="2" role="cell" style="padding-right: 0">
                    <!-- <button
                    class="btn btn-warning mr-1 btn-sm"
                    @click="viewLegalRegister(legal._id)"
                  >
                    View
                  </button> -->
                    <!-- <button
                    class="btn btn-primary mr-1 btn-sm"
                    @click="openEditLegal(legal._id)"
                  >
                    Edit
                  </button> -->
                    <button
                      class="btn btn-success mr-1 mt-1 btn-sm"
                      @click.stop="ApplicabilityReview(legal._id)"
                    >
                      Applicability Review
                    </button>
                    <!-- <button
                    class="btn btn-danger btn-sm"
                    @click="showModal(legal._id)"
                  >
                    Delete
                  </button> -->
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Legal Register Not Found">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      It seems like there are no Legal Registers at the moment.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>

          <div class="mt-3">
            <div class="row">
              <div
                class="d-flex align-items-center justify-content-center col-12"
              >
                <b-pagination
                  v-model="filters.page"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.rows"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BSidebar,
  BOverlay,
  BAvatar,
  BCollapse,
  BBadge,
  BModal,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  VBToggle,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import RiskAssessmentMixins from "@//mixins/RiskAssessmentMixins";
import ResponseMixins from "@//mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import DonutBar from "@/components/DonutBar.vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import vSelect from "vue-select";
import { data } from "vue-echarts";
export default {
  components: {
    BPagination,
    BButton,
    BSidebar,
    BFormInput,
    BOverlay,
    BBadge,
    BAvatar,
    BCollapse,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    DonutBar,
    flatPickr,
    ToastificationContent,
    EmptyTableSection,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      showOverlay: false,
      pagination: {
        lastPage: null,
        perPage: 10,
        rows: 1,
      },
      filters: {
        page: 1,
      },
      newRiskAssessment: {
        title: null,
        notes: null,
      },
      assessments: null,
      dateDefault: null,
      title: "",
      content: "",
      authority: "",
      internalpoc: "",
      type: "",
      country: "",
      userslist: [],
      legallist: [],
      legalid: "",
      legaldetails: [],
      authorities: [],
      applicability: "",
      applicability_note: "",
      assessmentDate: "",
      countries: [],
      industries: [],
      countryy: "",
      industryy: "",
      search: "",
      searchLegalTitle: "",
      partyname: "",
      parties: [],
      parties_suggestion: [],
      selectedInternalPOC: null,
      optionUsers: [],
      importStatus: false,
      selectedCountry: "",
      selectedIndustry: "",
    };
  },
  mixins: [ResponseMixins, RiskAssessmentMixins, UtilsMixins],
  filters: {
    moment: function (date) {
      if (!date) return "";
      if (!moment(date).isValid()) return "";
      return moment(date).format("D-MMM-YY");
    },
  },
  // computed: {
  //   isInputEmpty() {
  //     return this.selectedInternalPOC === '';
  //   }
  // },
  watch: {
    filters: {
      handler: function (newValue) {
        // this.getRiskAssessmentsAndSetData(newValue);
        this.getLegalList(newValue);
      },
      deep: true,
    },
    countryy(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getLegalList();
      }
    },
    industryy(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getLegalList();
      }
    },
  },
  mounted() {
    this.load();
    // this.getAuthority();
    this.getCountry();
    this.getIndustry();
  },
  methods: {
    getIntrestedParties() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/intrested-parties`,
      };
      this.$http(options)
        .then((res) => {
          // this.countries = res.data.data.data;
          this.parties_suggestion = res.data.data;
          console.log(JSON.stringify(res.data) + "Intrested parties");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearInputsModal() {
      this.selectedInternalPOC = null;
      this.selectedCountry = "";
      this.selectedIndustry = "";
    },
    importLRfromOnecsf() {
      this.importStatus = true;
      const data = {
        internal_poc_user_id: this.selectedInternalPOC.value,
        country: this.selectedCountry,
        industry: this.selectedIndustry,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/import-all-from-master`,
        data: data,
      };
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.selectedInternalPOC = "";
          this.importStatus = false;
          if (res.data.data.imported_count > 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Legal register imported successfully `,
                icon: "EditIcon",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Legal Registers already imported  `,
                icon: "EditIcon",
                variant: "warning",
              },
            });
          }

          this.importStatus = false;
          this.getLegalList();
          this.$refs["importLegalRegister"].hide();
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    importLegalRegModel() {
      this.$refs["importLegalRegister"].show();
      this.getInternalPocUsers();
    },
    getInternalPocUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/users?dont_paginate=1`,
      };
      this.$http(options)
        .then((res) => {
          this.optionUsers = [];
          res.data.data.map((user) => {
            const poc = {
              title: user.firstname + "" + user.lastname,
              value: user.user_id,
            };
            this.optionUsers.push(poc);
          });
          // this.optionUsers = res.data.data.data;

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddParties(party) {
      // this.parties.push(party);
      // this.partyname = '';
      if (this.partyname.trim() !== "" && !this.parties.includes(party)) {
        this.parties.push(party);
        this.partyname = "";
      }
    },
    removeParties(index) {
      this.parties.pop(index);
    },

    getCountry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/countries`,
      };
      this.$http(options)
        .then((res) => {
          this.countries = [];
          // Map the API response
          this.countries = res.data.data.data.map((country) => ({
            title: country.name,
            value: country._id,
          }));
          // Add the global option
          this.countries.unshift({
            title: "Global",
            value: "global",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchCountry(search) {
      if (search.length >= 2) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "/countries?search=" + search,
        };
        this.$http(options)
          .then((res) => {
            this.countries = res.data.data.data.map((country) => ({
              title: country.name,
              value: country._id,
            }));
            // Add the global option
            this.countries.unshift({
              title: "Global",
              value: "global",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    getIndustry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/industries`,
      };
      this.$http(options)
        .then((res) => {
          this.industries = [];
          this.industries = res.data.data.data.map((industry) => ({
            title: industry.name,
            value: industry._id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchIndustry(search) {
      if (search.length >= 2) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "/industries?search=" + search,
        };
        this.$http(options)
          .then((res) => {
            this.industries = res.data.data.data.map((industry) => ({
              title: industry.name,
              value: industry._id,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    AddReview() {
      const data = {
        applicability: this.applicability,
        applicability_note: this.applicability_note,
        assessment_date: this.assessmentDate,
        intrested_parties: this.parties,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${this.legalid}/assessments`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeApplicable();
          this.getLegalList();
          this.applicability = "";
          this.applicability_note = "";
          this.assessmentDate = "";
          this.parties = [];
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ApplicabilityReview(legalid) {
      this.legalid = legalid;
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
    },
    closeApplicable() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
      this.applicability = "";
      this.applicability_note = "";
      this.assessmentDate = "";
      this.parties = [];
    },
    viewLegalRegister(legalid) {
      this.$router.push({
        name: "legalassessment",
        params: { id: legalid },
      });
    },
    getAuthority() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSFURL + `/authorities`,
      };
      this.$http(options)
        .then((res) => {
          this.authorities = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      //   this.getRiskAssessmentsAndSetData(this.filters);
      this.getUsers();
      this.getLegalList();
    },
    createLegalRegister() {
      const data = {
        title: this.title,
        requirement: this.content,
        authority: this.authority,
        internal_poc_user_id: this.internalpoc,
        type: this.type,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeSidebar();
          this.getLegalList();
          this.title = "";
          this.content = "";
          this.dateDefault = "";
          this.authority = "";
          this.internalpoc = "";
          this.type = "";
          this.country = "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateLegalRegister() {
      const data = {
        title: this.title,
        requirement: this.content,
        effective_from: this.dateDefault,
        authority: this.authority,
        internal_poc_user_id: this.internalpoc,
        type: this.type,
        country: this.country,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeEditLegal();
          this.getLegalList();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/users?dont_paginate=true`,
      };
      this.$http(options)
        .then((res) => {
          this.userslist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLegalList(params) {
      this.showOverlay = true;
      let url = process.env.VUE_APP_BASEURL + "/legal-registers?query=true";
      if (this.searchLegalTitle) {
        debugger;
        url = url + `&search=${this.searchLegalTitle}`;
      }
      if (this.countryy) {
        url = url + `&country=${this.countryy}`;
      }
      if (this.industryy) {
        url = url + `&industry=${this.industryy}`;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
        params: params,
      };
      this.$http(options)
        .then((res) => {
          this.legallist = res.data.data.data;

          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getLegalDetails() {
      this.showOverlay = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.legaldetails = res.data.data;
          this.title = this.legaldetails.title;
          this.content = this.legaldetails.requirement;
          this.dateDefault = this.legaldetails.effective_from;
          this.authority = this.legaldetails.authority;
          this.internalpoc = this.legaldetails.internal_poc_user_id;
          this.type = this.legaldetails.type;
          this.country = this.legaldetails.country;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    deleteLegal() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/legal-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.getLegalList();
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditLegal(id) {
      this.legalid = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
      this.getLegalDetails();
    },
    closeEditLegal() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },

    handleRowClick(assessmentId) {
      this.$router.push(`/riskassessment/${assessmentId}/risks`);
    },

    getRiskAssessmentsAndSetData(params) {
      this.getRiskAssessments(params)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleNewRiskAssessmentModalSubmit() {
      this.storeRiskAssessment({
        title: this.newRiskAssessment.title,
        notes: this.newRiskAssessment.notes,
      })
        .then((res) => {
          this.load();
          this.handleResponse(res);
          // console.log("created", res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.hideModal();
        });
    },

    handleNewRiskAssessmentClick() {
      this.newRiskAssessment.title = null;
      this.newRiskAssessment.notes = null;
      this.showModal();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal(id) {
      this.legalid = id;
      this.$refs["my-modal"].show();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      this.title = "";
      this.content = "";
      this.dateDefault = "";
      this.authority = "";
      this.internalpoc = "";
      this.type = "";
      this.country = "";
    },
    clearSearch() {
      this.searchLegalTitle = "";
      this.getLegalList();
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="css" scoped>
.table td,
.table th {
  width: 10%;
  text-transform: capitalize !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
th {
  text-transform: capitalize !important;
}
</style>
